

@mixin custom_font_face($name, $file, $category:"", $path: "") {
    $filepath: $path + "/" + $file;
    @font-face {
        font-family: "#{$name}";
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
        // url($filepath + ".woff2") format('woff2'),
         url($filepath + ".woff") format('woff'),
         url($filepath + ".ttf")  format('truetype'), 
         url($filepath + ".svg#" + $name + "#{$name}") format('svg');
		font-style: normal;
		font-weight: normal;
		speak: none; 
		font-variant: normal;
		text-transform: none;  
    }
    %#{$name} {
        font: {
            @if $category != "" {
                family: "#{$name}", #{$category};
            }
            @else {
				family: "#{$name}";				
            }
        }
    }
}


// SET IMAGE PATH
//
// Retrun application assets with image name
$image-url-path: "resources/images/";

@function image-url($image) {
	@return url(""+$image-url-path + $image+"");
}

/* This function is used for font type PX to Em*/
$browser-context: 16; // Default desktop setting

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function rem($pixels, $context: $browser-context) {
	@return #{$pixels/$context}rem;
  }
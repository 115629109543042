@import "../../assets/scss/variables/_variable.scss";

.select-vendor-details-wrapper {
  display: flex;
  margin-bottom: em(14);

  .vendor-logo {
    width: em(82);
    height: em(82);
    margin-right: em(23);
  }

  .address-details {
    p {
      font-size: em(13);
      margin: 0;
      padding: 0;
      line-height: rem(26);

      &.label-vendor-name {
        font-weight: 700;
      }

      .label-vendor-number {
        margin-bottom: rem(6);
      }
    }
  }
}

.info-invoice-number {
  .ant-space {
    width: 100%;
  }
}

.info-invoice-number {
  .f-info-label {
    width: 100%;
    display: inline-block;
    text-align: right;

    &.label-heght-t1 {
      line-height: rem(30);
    }
  }

  .status-tag-p1 {
    border-radius: rem($base-border-radius);
    border: solid 2px $primary;
    padding: rem(7) rem(14) rem(6) rem(14);
    float: left;
    line-height: 1;
  }

  .status-tag-p2 {
    @extend .status-tag-p1;

    border-color: $coral;

    &::before {
      background-color: $coral;
    }
  }
}

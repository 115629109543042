@import "../../assets/scss/variables/_variable.scss";

.btn-orange {
  background-color: $orange;
  color: $white;
  width: 150px;
  height: 40px;
  border-radius: 6px;
}

.mr-5 {
  margin-right: 5px;
}

@import "../../assets/scss/variables/_variable.scss";

.upload-wrapper {
  background-color: $catskill-white;
  border-radius: em($base-border-radius);
  border: dashed 2px $gallery;
  // height: em(305);
  margin: em(24) 0 em(4);
  padding: em(55) 0 em(37) 0;
  text-align: center;

  .ant-upload.ant-upload-drag {
    background: none;
    border: none;
  }

  .icon-upload-contract {
    font-size: em(72);
  }

  .label-upload {
    font-size: rem(18);
    line-height: em(22);
    color: $manatee;
    font-weight: 500;
    margin: em(22) 0 em(14) 0;
  }

  .label-or {
    font-size: rem(15);
    line-height: em(15);
    color: #b3b4b7;
    margin: 0 0 em(14) 0;
  }
}

.btn-upload {
  border: solid rem(2) $primary;
  border-radius: em($base-border-radius);
  line-height: em(32);
  color: $primary;
  font-size: rem(13);
  cursor: pointer;
  font-weight: 600;
  height: rem(34);
  width: rem(104);
  display: inline-block;

  input {
    display: none;
  }
}

.uplaod-msg-wrapper {
  margin: 0 0 em(12) 0;
  text-align: right;

  span {
    text-align: right;
    font-size: em(12);
    color: red;
  }
}

.no-file-choose-wrapper {
  text-align: center;
  color: #cdceda;

  .label-no-file {
    font-size: rem(23);
    margin: em(28) 0 em(8) 0;
  }

  .label-info-upload {
    font-size: rem(18);
  }
}

.table-file-uploaded {
  table {
    .ant-table-thead > tr > th {
      color: $manatee;
    }

    .row-item {
      td {
        border-bottom: solid 8px $white;
        background: $athens-gray;
        vertical-align: baseline;
        padding-bottom: 0;

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            height: rem(32);
          }
        }

        .icon-minus {
          font-size: rem(20);
          margin: rem(2) 0 0 0;
          cursor: pointer;
          // display: flex;
        }

        .ant-select {
          &.ant-select-single {
            .ant-select-arrow {
              top: 26%;
              margin-top: 0;
            }
          }
        }

        .ant-select-selector {
          height: rem(32);
        }

        .ant-select-selection-search {
          line-height: rem(32);
        }

        .ant-select-selection-search {
          line-height: rem(35);
        }

        .ant-form-item-control-input-content {
          .ant-input {
            height: em(32);
          }
        }

        .ant-select-selection-placeholder {
          line-height: rem(33);
          font-size: rem(11.5);
        }

        .ant-form-item-explain-error {
          font-size: rem(12);
          line-height: rem(12);
          margin: 2px 0 0 0;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .ant-select-selection-item {
          line-height: rem(32);
        }
      }

      td:first-child {
        border-radius: 6px;
      }

      td:last-child {
        border-radius: 6px;
      }
    }
  }

  .ant-btn {
    margin-top: em(20);
  }
}

// Select Contract UI

.select-contract-wrapper {
  height: 100%;
  text-align: center;
  justify-content: center;
  display: flex;

  .select-contract-inner-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    max-width: em(810);
    margin: 0 auto;

    .btn-template-box {
      display: flex;
      flex-direction: column;
      width: em(250);
      height: em(197);
      margin: em(54) em(18) em(18) 0;
      padding: em(20) em(20);
      border-radius: 3px;
      box-shadow: 0 3px 25px 0 rgba(0, 25, 26, 0.09);
      cursor: pointer;
      background-color: $white;
      border: solid 1px $white;

      .icon-select-tick {
        display: none;
        &::before {
          background-color: $primary;
          border-radius: 50%;
          position: absolute;
          display: block;
        }
      }

      &.active {
        box-shadow: 0 3px 25px 1px #cbdbd9;
        border: solid 1px $primary;

        .icon-select-tick {
          display: block;
        }
      }

      .ico-template {
        font-size: rem(47);
        background-color: #f6f8fd;
        width: 70px;
        height: 66px;
        display: flex;
        margin: 24px auto 24px;
        justify-content: center;
        align-items: center;
      }

      .label-template-type {
        font-size: em(18);
        text-align: center;
        font-weight: bold;
        color: $scorpion;
        width: 100%;
        display: inline-block;
      }

      &:hover {
        box-shadow: 0 3px 25px 1px #cbdbd9;
        border: solid 1px $primary;
      }

      &:last-child {
        margin-right: 0;
      }

      &.disable {
        opacity: 0.6;
        border-color: #efecec;
        cursor: not-allowed;
        background-color: #00000014;
        &:hover {
          box-shadow: 0 3px 25px 0 rgba(0, 25, 26, 0.09);
        }
      }
    }
  }

  .row-btn-request {
    max-width: em(788);
    // max-width: em(244);
    text-align: right;
    margin: 0 auto rem(36);

    a {
      color: $primary;
      font-size: em(13);
      font-weight: 600;
    }
  }
}

.upload-datepicker .ant-form-item-control .ant-form-item-explain-error > div {
  margin-top: 10px !important;
}

.pdf-viewer-wrapper {
  position: relative;
  max-height: 400px;
  overflow-y: auto;
  margin: rem(-34) rem(-29) rem(55);
  overflow-x: hidden;
  border-bottom: solid 1px #cdcdce;  

  .pdf-actions-box {
    position: sticky;
    right: 0;
    bottom: rem(18);
    display: flex;
    margin: 0 0 0 92%;

    .icon-expand,
    .icon-arrow-up {
      font-weight: 600;
      cursor: pointer;
      background: $black;
      width: rem(34);
      height: rem(30);
      margin: 0 0 0 rem(12);
      padding: rem(7) rem(9);
      border-radius: $border-radius-type-1;

      &::before {
        color: $white;
      }
    }
  }
}

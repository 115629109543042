.ant-menu-item-selected{
	color: $clr-primary-nav-link-selected;
}

.ant-layout{
	background: $body-bg;
	min-height: 100vh;
}

.ant-layout-content {
	background: $white;
	border-radius: em(8);
	box-shadow: 0 3px 25px 0 rgba(0, 25, 26, 0.09);
	margin: em(24) em(24) em(12) em(24);
	padding: em(34) em(29) em(34) em(30);
}

.dashboard-card-t1{
	background: $white;
	border-radius: em(8);
	box-shadow: 0 3px 25px 0 rgba(0, 25, 26, 0.09);
}


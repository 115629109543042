:root {
  --progress-width: 50%;
}

body {
  background: #f8f9fb;
  /* padding-top: 100px; */
}

.mb-20 {
  margin-bottom: 20px;
}

.non-active-heading {
  color: #888997 !important;
}

.active-heading {
  color: #333333 !important;
}

.d-none {
  display: none !important;
}
.desc-between-ytd {
  color: #414042;
  font-size: 18px;
  margin-bottom: 15px !important;
  font-weight: bold;
}

.container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.card-custom {
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.card-custom .heading-card {
  margin-bottom: 10px;
}
.card-custom .heading-card h3 {
  color: #071437;
  font-size: 16px;
  line-height: 21.33px;
}

.custom-progress-tracker-wrap.topTooltip {
  background: transparent;
  border: none;
  overflow: initial;
}
.custom-progress-tracker-wrap.topTooltip .progress-track {
  height: auto;
}
.custom-progress-tracker-wrap.topTooltip .progress-track .progress-tier {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  overflow: inherit;
  position: relative;
  height: 30px;
}
.custom-progress-tracker-wrap.topTooltip .progress-track .progress-tier .toolTiper {
  width: 50px;
  height: 22px;
  background-color: #089196;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin: 0 0 10px calc(0px - 25px);
  position: absolute;
}
.custom-progress-tracker-wrap.topTooltip .progress-track .progress-tier .toolTiper:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 7px solid #089196;
  border-bottom: 0;
  position: absolute;
  bottom: -8px;
}

.custom-progress-tracker-wrap {
  background-color: #f0f0f0;
  border-radius: 6px;
  overflow: hidden;
  border: 2px solid #f0f0f0;
}
.custom-progress-tracker-wrap .progress-track {
  height: 63px;
  width: 100%;
  display: flex;
}
.custom-progress-tracker-wrap .progress-track .progress-tier {
  display: flex;
  height: 63px;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid #fff;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.custom-progress-tracker-wrap .progress-track .progress-tier:last-child {
  border: none;
}
.custom-progress-tracker-wrap .progress-track .progress-tier .txt-wrap {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.custom-progress-tracker-wrap .progress-track .progress-tier h3 {
  padding: 0;
  margin: 0;
  color: #fff;
  text-shadow: 0px 0px 3px #ccc;
}
.custom-progress-tracker-wrap .progress-track .progress-tier p {
  font-size: 14px;
  color: #a0a0a0;
  margin: 5px 0 0 0;
}
.custom-progress-tracker-wrap .progress-track .progress-tier .progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  background: #089196;
  transition: all 0.5s ease;
  width: 100%;
  max-width: 0;
}

.custom-progress-tracker-wrap.bottomNumbers {
  background: transparent;
  border: none;
}
.custom-progress-tracker-wrap.bottomNumbers .progress-tier .trierNumber {
  font-size: 12px;
  color: #7a829b;
  padding: 5px 0;
  width: 30px;
  text-align: center;
}
.custom-progress-tracker-wrap.bottomNumbers .progress-tier:first-child .trierNumber {
  margin: 0;
  text-align: left;
}
.custom-progress-tracker-wrap.bottomNumbers .progress-tier .trierNumber {
  margin: 0 0 0 calc(0px - 15px);
}
.custom-progress-tracker-wrap.bottomNumbers .progress-track {
  height: auto;
}
.custom-progress-tracker-wrap.bottomNumbers .progress-track .progress-tier {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  overflow: inherit;
}

@media (max-width: 1369px) {
  .desc-between-ytd {
    font-size: 16px;
  }
}

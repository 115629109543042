@import "../../../assets/scss/variables/_variable.scss";

.login-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;

  .bg-brand-clr {
    background: url("../../../assets/images/bg-login.png") no-repeat center center $primary;
    align-items: center;   
    background-size: cover;
    display: flex;
    height: inherit;
    justify-content: center;
    width: 100% !important;
  }

  .bg-form-box-clr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.login-wrapper-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .login-block {
    height: 100%;
    width: 100%;
    min-height: em(648);

    .app-logo {
      display: block;
      margin: em(64) auto 0 auto;
      text-align: center;

      img {
        width: em(320);
      }
    }

    .btn-request{
      line-height: rem(40);
      font-weight: 600;
      letter-spacing: em(1.5);
    }
  }

  .login-form-wrapper {
    width: rem(552);
    margin-right: em(32);
    margin-left: em(32);
    padding: em(16) 0;

    h2 {
      margin: 0 0 em(30) 0;
      text-align: center;
      font-weight: 600;
      font-size: em(28);
    }

    .request-header{
      width: 100%;
      text-align: center;

      h2{
        display: inline-block;
        margin: 0 0 em(15) 0;
      }

      .btn-request-back{
        float: left;
        font-size: rem(20);
        font-weight: 900;
        margin: em(4) 0 0 0;
      }
    }

    .cc-form-group {
      position: relative;
      padding-top: em(16);

      .ant-form-item-label {
        padding-bottom: em(2) !important;
      }

      .ant-form-item-has-error .ant-input, 
      .ant-form-item-has-error .ant-input-affix-wrapper, 
      .ant-form-item-has-error .ant-input:hover, 
      .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        background-color: #fff !important;
        border-color: #ff4d4f !important;
      }

      .ant-input-affix-wrapper {
        padding: 0;
        border: solid 1px $alto;
        border-radius: $input-border-radius + px;

        &:focus,
        &:hover,
        &.ant-input-affix-wrapper-focused {
          border-color: $primary;
          -webkit-box-shadow: 0 0 0 2px #bedfda;
          box-shadow: 0 0 0 2px #bedfda;
          .ant-input-suffix {
            color: $scorpion !important;
          }
        }

        .ant-input-suffix {
          margin-left: -27px;
          color: $scorpion !important;
          z-index: 12;
        }

        input[type="text"],
        input[type="password"] {
          border-radius: $input-border-radius + px;
          letter-spacing: 0.3px;
          line-height: rem(42);
          background-color: $white;
          border: none;
          color: $mine-shaft;
          font-size: em(16);
          height: rem(42);
          padding: 0 rem(18);
          width: 100%;

          &::-webkit-input-placeholder {
            /* Edge */
            font-size: em(14);
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-size: em(14);
          }

          &::placeholder {
            font-size: em(14);
          }
        }     
      }     
      
      .lnk-forgot-password {
        font-size: rem(15);
        padding: 0;
        font-weight: 600;
        margin: 0;

         &:hover{
          color: $clr-link-hover;
        }

      }

      .btn-login{
        background: $primary;
        border-radius: $btn-border-radius + px;
        color: $white;
        font-size: rem(16);
        font-weight: 600;
        height: rem(50);
        margin-top: rem(35);

        &:hover{
          background-color: $clr-link-hover;
          border-color: $clr-link-hover;
        }
      }

      .btn-signup{
        background: $red;
        border-radius: $btn-border-radius + px;
        color: $white;
        font-size: rem(16);
        font-weight: 600;
        height: rem(50);
        margin-top: rem(10);

        &:hover{
          background-color: $clr-link-hover;
          border-color: $clr-link-hover;
        }
      }
    }
  }
  .login-brand {
    color: $white;
    text-align: center;
    padding: 0 em(42) 0 em(42);

    .bg-splash {
      margin: em(92) 0 em(53) 0;
      width: em(443);
    }

    h1 {
      font-weight: 700;
      font-size: em(24);
      font-stretch: normal;
      font-style: normal;
      line-height: em(46);
      color: $white;
      letter-spacing: 1.2px;
      margin: 0 0 em(12) 0;
    }
  }
}

@import "../assets/scss/variables/_variable.scss";

.report-filter-box-wrapper{
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  margin: 0 12px 12px;
}

.tiles-container {
  display: flex;
  flex-wrap: wrap;
}

.report-tile {
  display: flex;
  justify-content: space-between;
  // width: 23%;
  margin: 10px;
  border-radius: rem($base-border-radius);
  background: url("../assets/images/reportTile.svg") no-repeat center center;
  background-size: cover;
  cursor: pointer;
  min-height: 90px;

  .title{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    padding: 0 25px 0 0;
    text-align: left;
    width: 100%;
  }

}

.report-tile__active {
  background-image : url("../assets/images/blue-bg-report.svg");
  color: white;
}

.report-tile > .ant-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  width: 100%;
  flex: 1;
}

.title {
  font-size: 14px;
  margin: 4%;
}

.report-tile-icon {
  font-size: 30px;
  color: #08979c;
  cursor: pointer;
}

.report-tile__active-icon {
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.ant-layout-content {
  // margin-top: 20px;
  // margin-left: 10px;
}

.view-report-btn {
  border: #08979c;
  color: 08979c;
  background-color: white;
}

.report-counter{

}


.breaker-t2{
  border: 0;
  height: 2px;
  background: #ebebeb;
  margin: 12px 0 32px 0;

}

.reports-chart-wrapper{

  margin-top: rem(24);

  .dashboard-card-t1{

    background: transparent;
    box-shadow: none;
    border: none;
    border-radius: 0;
    .ant-card-body{
    padding: 12px 0 12px 0;
  }


  } 

 .dashboard-card-t1:first-child::after{
    content: "";
    width: 1px;
    position: absolute;
    height: 100%;
    top: 0;
    right: -12px;
    // background: red;

}
}
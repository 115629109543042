@media (min-width: 1401px) and (max-width: 1699.98px) {
    body {
        font-size: 13px;
    }
}

@media (min-width: 980px) and (max-width: 1400px) {
    body {
        font-size: 10px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    body {
        font-size: 10px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    body {
        font-size: 10px;
    }
}

@media (max-width: 767px) {
    body {
        font-size: 10px;
    }
}
.upload-dropdown {
  width: 22% !important;
  margin-bottom: 40px;
  margin-left: 10px;
}

.mb-25 {
  margin-bottom: 25px;
}

.loading-text {
  display: flex !important;
  justify-content: center !important;
  font-size: 15px !important;
}

.ant-select.ant-select-single {
  width: 22% !important;
}

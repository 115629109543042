.customscrollHeight {
  height: 338px;
  //   max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
}

.w-full {
  width: 100%;
}
// .customscrollHeight::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
//   background-color: #f9fafb;
// }

.customscrollHeight::-webkit-scrollbar {
  width: 6px;
  //   background-color: #ccc;
  height: 2px;
}

.customscrollHeight::-webkit-scrollbar-thumb {
  background-color: var(--primary-blue);
  border: 6px solid #555555;
}

.no-padding-top {
  padding-top: 0px !important;
}
.db-row.no-mb {
  margin-bottom: 0;
}

.word-break {
  word-break: auto-phrase;
}

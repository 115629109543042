// http://chir.ag/projects/name-that-color/

//Custom app theme colors from app design

$white: #ffffff;
$black: #000000;

$mine-shaft: #232323;
$niagara: #08979c; // main primary
$alto: #dadada; // input feildf
$gallery: #ebebeb; // inner form feild border color
$pine-green: #006d75; // link color
$scorpion: #5a5a5a; // feild label color or main font
$manatee: #888997; // main nav
$ship-gray: #414042; // main nav link active
$athens-gray: #f8f9fb; // app bg

$java: #13c2c2;
$catskill-white: #fbfcfd;
$charcoal-grey: #302f33;
$bridesmaid: #fef2ee;
$coral: #fb7d59;
$bittersweet: #f87b57;
$dark-black: #414042;
$dark-gray: #6c6c6c;
$red: #fb7d59;
$dark-gray-1: #f0f0f0;
$bg-gray: #f3f4f6;
$small-gray: #888997;
$orange: #fb7d59;
$green-max: #059aa0;
$green-min: #2fc9ce;
$grayy: #f0f0f0;

/* ---------------------------------- new work */

$primary: $niagara;
$secondary: $mine-shaft;

// Body
//
// Settings for the `<body>` element.

$body-bg: $athens-gray;
$body-color: $scorpion;

// $bg-clr-app-section : $mine-shaft;/

// Header
// $bg-color-dashborad-header:  $blue-zodiac;
// $bg-color-internal-header : $white;
// $clr-heading-type2 : $black;
// $clr-header-second : $dove-gray;

$clr-link: $pine-green;
$clr-link-hover: #13c2c2;

// Primary Nav
$clr-primary-nav-link: $manatee;
$clr-primary-nav-link-selected: $ship-gray;

// $clr-bg-dashboard-basic-box : $white;

// Heading color
// $clr-head-t1 : $black ;

// scroll color
// $bg-clr-scroll :  $golden-grass;

// // tabs menu color
// $clr-menu-item : $dove-gray;
// $clr-menu-item-active : $black;

// $clr-label: $dove-gray ;
// $clr-value: $black ;

// buttons

// btn edit
// $clr-btn-edit : $dove-gray;
// $clr-btn-edit-hover : $black;

// btn remove
$clr-btn-remove: #ff5353;
$clr-btn-remove-hover: #c61616;

// $clr-field-border: $aluminium;

// tertiary button setting
// $clr-tertiary: $white;
// $bg-clr-tertiary : $nepal;
// $clr-border-tertiary : $nepal;

// $clr-hover-tertiary :  $ebony-clay;

// $bg-section-t3 : $white-lilac;

/* footer setting*/
// $bg-footer : $mirage;

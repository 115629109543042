@import "../../assets/scss/variables/_variable.scss";

.card-heading {
  font-size: 38px;
  color: $dark-black;
  margin-bottom: 24px !important;
}
.card-border {
  border: 2px solid $dark-gray-1;
  margin: 20px 0px;
}

.bg-white {
  background-color: $white;
}

.mb-20 {
  margin-bottom: 20px;
}

.heading {
  font-weight: bolder;
}

.green {
  color: $green-max !important;
}
.orange {
  color: $orange !important;
}

.mt-20 {
  margin-top: 30px;
}
.pl-25 {
  padding: 0px 25px;
}
.pr-50 {
  padding-right: 60px !important;
}

.bg-gray {
  background-color: #f8f9fb;
  padding: 15px 25px;
}

.color-black {
  color: $dark-black;
  font-size: 14px;
  font-weight: bold;
}
.color-orange {
  color: $orange !important;
  font-size: 14px;
}

.p-18 {
  padding-top: 45px;
  padding-left: 25px;
  padding-bottom: 15px;
  padding-right: 20px;
}

.border-radius-10 {
  border-radius: 10px;
}
.super-item {
  position: absolute;
  top: 15px;
}

.desc-between {
  color: $dark-black;
  font-size: 16px;
  margin-bottom: 15px !important;
  font-weight: bold;
}

.desc-between-ytd {
  color: $dark-black;
  font-size: 18px;
  margin-bottom: 15px !important;
  font-weight: bold;
}

.desc-bottom {
  color: $dark-gray;
  font-size: 15px;
}

.danger {
  color: $red;
}

.flex {
  display: flex;
}

.flex-between {
  justify-content: space-between;
}

.font-18 {
  font-size: 18px !important;
}

.gap-50 {
  gap: 50px;
}
.gap-120 {
  gap: 120px;
}
.heading-bottom {
  color: $small-gray;
  font-size: 14px;
  position: relative;
  bottom: 0px;
}

.spend-rebates {
  color: white;
  padding: 20px;
  font-size: 18px;
}
.rebates {
  background-color: $green-max;
}

.non-rebates {
  background-color: $coral;
}

.max {
  width: 20vw;
}
.min {
  width: 10vw;
}

.rebates-title {
  color: $small-gray;
  font-size: 13px;
}

// YTD Styles
.view-all {
  color: $green-max;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
}

.font-20 {
  font-size: 24px !important;
  font-weight: bold;
}

.section {
  // display: flex;
  position: relative; /* Position relative for positioning ::before pseudo-element */
}

.ytd-breakdown-legend {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Style for the vertical line using ::before */
.section::before {
  content: "";
  border-left: 3px solid $dark-gray-1; /* Set the color of the line */
  height: 100%; /* Vertical line height */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -5px; /* Adjust margin as needed */
}

.ant-layout-content {
  background: $bg-gray;
  border-radius: em(8);
  box-shadow: 0 3px 25px 0 rgba(0, 25, 26, 0.09);
  margin: em(24) em(24) em(12) em(24);
  padding: em(34) em(29) em(34) em(30);
}

.f-width {
  width: 100%;
}

.rowFlexCustom {
  flex-flow: nowrap !important;
}

.cardWidth {
  min-height: 260px;
}

.centerObject {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 215px);
}

.space-between {
  justify-content: space-between;
}

@media (max-width: 1500px) {
  .f-width {
    width: 23%;
  }
  .rowFlexCustom {
    flex-flow: wrap !important;
  }

  .cardWidth {
  }

  .gap-120 {
    grid-gap: 0;
    gap: 0;
  }

  .box100 {
    width: 100%;
  }
}

@media (max-width: 1760px) {
  .d-blockMed {
    display: block;
  }

  .d-blockMed.section::before {
    content: none;
  }
}

@media (max-width: 1765px) {
  .customscroll {
    max-height: 500px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .customscroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    background-color: #f9fafb;
  }

  .customscroll::-webkit-scrollbar {
    width: 6px;
    background-color: #ccc;
    height: 6px;
  }

  .customscroll::-webkit-scrollbar-thumb {
    background-color: var(--primary-blue);
    border: 8px solid #555555;
  }
}

@media (max-width: 1369px) {
  .f-width {
    width: 22%;
  }
  .card-heading {
    font-size: 23px;
    margin-bottom: 14px !important;
  }
  .card-heading img {
    width: 12px;
    margin-right: 5px;
  }

  .desc-between {
    font-size: 12px !important;
    margin-bottom: 5px !important;
  }

  .desc-bottom {
    font-size: 11px !important;
  }

  .cardWidth {
    padding-top: 20px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    min-height: 190px;
  }
  .cardWidth .mt-20 {
    margin-top: 10px;
  }

  .desc-between-ytd {
    font-size: 16px;
  }
}
